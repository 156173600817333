// Customizable Area Start
import React from "react";

import JobListingController, {
  configJSON,
  SortingBySideBar,
  SortingByWorkType,
  TabPanelProps,
} from "./JobListingController";
import { Accordion, AccordionDetails, AccordionSummary, Box, Button, Checkbox, CircularProgress, createTheme, Divider, FormControlLabel, Grid, IconButton, InputAdornment, MenuItem, MenuList, Paper, Slider, styled, Tab, Tabs, TextField, ThemeProvider, Tooltip, Typography } from "@material-ui/core";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import WorkOppertunityModal from "../../landingpage/src/workopportunity/WorkOppertunityModal.web"
import OpportunityCard, { workOpportunity } from "../../../components/src/OpportunityCard.web";
import SearchIcon from '@material-ui/icons/Search';
import { Pagination } from "@material-ui/lab";
import { dancingBar, findWorkPoster, Layer_1, searchImage } from "./assets";
import SelectLocation from "../../../components/src/SelectLocation.web"
import CreativeFields from "../../../components/src/CreativeFields.web"
import NavigationMenu from "../../navigationmenu/src/NavigationMenu.web";
import SortBy from "../../../components/src/SortBy.web";
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import { Proposals } from "./Proposals.web";
import { DraftOpportunity } from "./DraftOpportunity.web";

const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1611,
      xl: 1980,
    },
  },
});

class JobListing extends JobListingController {

  MainWrapper = styled(Box)(({ theme }) => ({
    "& .find_work_side_bar_container_menu": {
      width: "100%",
      backgroundColor: "#171717",
      margin: 0,
    },
    "& .find_work_main_container": {
      display: "flex",
      width: '100%',
      backgroundColor: "#111111"
    },
    "& .find_work_sidebar_accordian": {
      display: "flex",
      flexDirection: 'column',
      width: "100%"
    },
    "& .edit_profile_disco_bar": {
      marginLeft: '-17.5rem',
      width: '100%',
      marginBottom: 20
    },
    "& .find_work_side_bar_container": {
      border: 1,
      width: "25%",
      padding: 20,
      backgroundColor: "#111111"
    },
    "& .find_work_right_main_container": {
      width: "75%",
      border: 1,
      padding: 20
    },
    "& .find_work_grid_section": {
      width: "98%",
    },
    "& .find_work_search_field": { borderRadius: 20, outline: "none", marginBottom: 10, width: "95%", backgroundColor: '#171717', "::placeholder": { color: '#66625C' }, color: '#66625C' },
    '&:focus, &:hover': {
      outline: 'none',
    },
    "& .gray_color": {
      color: '#FFF2E3',
      fontFamily: "Inter , sans-serif",
    },
    "& .right_side_grid_box": {
      width: "95%",
      padding: 2
    }, "& .main_container_search_button": { color: "#FFF2E3" },
    "& .tabs_css": {
      color: "#FFF2E3",
      fontWeight: 700,
      fontFamily: "Inter,sans-serif",
      textTransform: 'none',
      fontSize: 16
    },
    "& .apply_button": {
      backgroundColor: "#FFF2E3", fontFamily: "Inter , sans-serif",
    },
    '& .MuiTabs-indicator': {
      backgroundColor: "#FFF2E3",
      height: '3px',
      widht: "40% !important",
      marginLleft: '10%'
    },
    '& .pagination .MuiPaginationItem-root': {
      color: '#8C8C8C',
    },
    '& .pagination .MuiPaginationItem-root.Mui-selected': {
      backgroundColor: '#F5F5DC',
      color: '#121212',
      borderRadius: '50%',
    },
    '& .find_work_search_field::placeholder': {
      color: '#66625C',
    },
    '& .find_work_search_field:focus': {
      outline: 'none',
    },
    "& .find_work_and_praposals_button": { display: 'flex', justifyContent: 'space-between', alignItems: "center", padding: 6, backgroundColor: '#0D0D0D', borderRadius: 50, marginBottom: 20 }
    , "& .praposal_text": { color: '#8C8C8C', fontFamily: "Inter,sans-serif", fontSize: '20px', padding: '5% 10%', cursor: 'pointer' },
    "& .sidebar_menu_box": { backgroundColor: "#111111", padding: 2 }, "& .sidebar_menu_list": { padding: 0 }, "& .sidebar_menu_item": { display: "flex", flexDirection: 'column', padding: 0 }, "& .accordian_details": { display: "flex", justifyContent: 'space-between', alignItems: 'center', width: '100%', marginTop: '12px' }, "& .divider": { backgroundColor: '#3E3E3E', width: '100%', borderBottomWidth: 2 }, "& .divider_for_tabs": { backgroundColor: '#3E3E3E', width: '100%', borderBottomWidth: 2 },
    '& .MuiSlider-track': {
      border: 'none',
      height: 8,
      background: 'linear-gradient(224.84deg, #FFC29F 0%, #FFB9C7 16.15%, #CF8CCC 33.85%, #AB9EF5 50.52%, #6C98EE 67.71%, #1CBAE0 83.85%, #A8E5EC 100%)'
    },
    "& .MuiSlider-root": {
      width: "97%",
      // marginLeft: '-2.5rem',
    },
    '& .MuiSlider-thumb': {
      height: 20,
      width: 20,
      backgroundColor: '#fff',
      border: '2px solid #171717',
      '&:focus, &:hover, &$active': {
        boxShadow: 'inherit',
      },
    },
    '& .MuiSlider-rail': {
      height: 8,
      opacity: 1,
      backgroundColor: '#bfbfbf',
    },
    '& .MuiSlider-valueLabel': {
      display: 'none',
    },
    '& .budget_min_max_box': {
      backgroundColor: '#222222', padding: '10 15', borderRadius: '5%'
    },
    '& .budget_count_box': {
      display: 'flex', justifyContent: 'space-around', alignItems: 'center'
    }, "& .budget_min_max_color": {
      color: '#797979', fontFamily: "Inter , sans-serif",
    }, '& .budget_count_color': {
      color: '#FFF2E3', fontFamily: "Inter , sans-serif",
    }, '& .to_padding': {
      padding: 10
    }, "& .MuiTab-wrapper": {
      display: 'flex',
      justifyContent: "start",
    }, "& ..MuiTab-root": {
      minWidth: 0,
    }, "& .font_weight_600": {
      fontWeight: 600
    }, "& .disabled-btn": {
      backgroundColor: 'rgb(157, 155, 153)',
      color: "black"
    }, "& .slider_container": {
      display: 'flex', flexDirection: 'column', flexGrow: 1, width: "100%", marginTop: -41
    },
    "& .opportunity_card_main_container": {
      borderRadius: 13,
      padding: "22px 20px 22px 20px",
      gap: 24,
      border: "1px solid #3E3E3E",
      cursor: "pointer",
    },
    "& .scroll::-webkit-scrollbar":{
        display:"none",
    },
    "& .opportunity_card_main_containerChangeColor": {
      cursor: "pointer",
      position: 'relative',
      borderRadius: '13px',
      background: '#313131',
      color: 'white',
      textTransform: 'none',
      fontWeight: 'bold',
      fontFamily: "Inter,sans-serif",
      fontSize: '20px', 
      padding: '22px 20px 22px 20px',
      zIndex: 1,
      '&::before': {
        content: '""',
        position: 'absolute',
        inset: 0,
        borderRadius: '13px',
        padding: '2px',
        background: 'linear-gradient(224.84deg, #FFC29F 0%, #FFB9C7 16.15%, #CF8CCC 33.85%, #AB9EF5 50.52%, #6C98EE 67.71%, #1CBAE0 83.85%, #A8E5EC 100%)',
        WebkitMask: 'linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0)',
        WebkitMaskComposite: 'xor',
        maskComposite: 'exclude', 
      }

    },
    "& .opportunity_card_person_city": {
      fontSize: "14px",
      fontFamily: "Inter,sans-serif",
      color: "#FFF2E3",
      fontWeight: 400,
    },
    "& .opportunity_card_price_duration": {
      fontSize: "14px",
      padding: "6px 16px 6px 16px",
      borderRadius: "24px",
      backgroundColor: "#1D1D1D",
      fontFamily: "Inter,sans-serif",
    },
    "& .opportunity_card_price_color": {
      color: "#1CBAE0"
    }, "& .opportunity_card_duration_color": {
      color: "#FFB9C7",
      marginLeft: 15
    },
    "& .opportunity_card__description": {
      fontSize: "12px",
      height: 50,
      fontFamily: "Inter,sans-serif",
      fontWeight: '400',
      color: "#FFF2E3",
      flexGrow: 1,
    },
    "& .opportunity_card_price_duration_box": {
      display: 'flex'
    }, "& .only_2_line": {
      display: "-webkit-box",
      WebkitBoxOrient: "vertical",
      WebkitLineClamp: 2,
      overflow: "hidden",
      textOverflow: "ellipsis",
      lineHeight: 1.5,
      maxHeight: "3em",
    },
    "& .opportunity_card_title_box": {
      borderColor: '#3E3E3E',
      display: 'flex',
      flexDirection: 'column',
      fontFamily: "Inter , sans-serif",
      alignItems: "stretch",
      gap: 14,
      justifyContent: "space-between",
    },
    "& .opportunity_card_divider_color": {
      borderColor: '#3E3E3E'
    },
    "& .opportunity_card_hour": {
      color: "#66625C",
      fontSize: "11px",
      fontWeight: 400,
      fontFamily: "Inter,sans-serif",
    },
    "& .opportunity_card_title": {
      fontSize: "20px",
      fontFamily: "Inter,sans-serif",
      color: "#FFF2E3",
      fontWeight: 600,
      height: 40
    },
    "& .opportunity_card_person_name": {
      fontSize: "14px",
      fontFamily: "Inter,sans-serif",
      color: "#FFF2E3",
      fontWeight: 600
    }, "& .opportunity_card_person_detain_box": {
      display: "flex",
      justifyContent: 'space-between',
      alignItems: "baseline",
      marginTop: 10,
    },
  }));

  CustomTextField = styled(TextField)({
    backgroundColor: '#1c1c1c',
    margin: "0px 0px 20px 0",
    fontSize: 14,
    fontFamily: "Inter , sans-serif",
    borderRadius: 20,
    '& .MuiOutlinedInput-root': {
      overflow: "hidden",
      '& fieldset': {
        borderColor: 'transparent',
      },
      '&:hover fieldset': {
        borderColor: 'transparent',
      },
      '&.Mui-focused fieldset': {
        borderColor: 'transparent',
      },
    },
    '& .MuiInputBase-input': {
      color: '#fff',
      fontSize: 14,
      '&:placeholder': {
        textDecoration: 'italic',
      },
    },
    '& .MuiInputAdornment-root': {
      color: '#66625C',
    }
  });

  SearchButton = styled(IconButton)({
    color: '#fff',
    textTransform: 'none',
    fontSize: 14,
    textDecoration: 'underline',
    fontFamily: "Inter , sans-serif",
    borderLeft: '1px solid #434343',
    borderRadius: '0px',
    '&:disabled': {
      color: '#888',
      textDecoration: 'none', // Remove underline if needed
      opacity: 0.5, // Reduce opacity to make it look disabled
    },
  });


DropDownBox = styled(Box)({
    position:"relative",
    display: "flex",
    height: "53px",
    gap: "0px",
    borderRadius: "6px",
    border: "1px solid #595959",
    justifyContent: "space-between",
    alignItems: "center",
    opacity: "0px",
    cursor:"pointer"

});

DropDownBoxTypography = styled(Typography)({
    fontFamily: "Inter",
    fontSize: "24px",
    fontWeight: 600,
    lineHeight: "29.05px",
    textAlign: "left",
    color: "#FFF2E3",
    display: "flex",
    alignItems: "center",
    marginLeft: "10px",
    gap: "10px",
"@media (max-width:960px)": {
  fontSize: "15px !important",
  fontWeight: "300px !important",
  lineHeight: "29.05px !important",
  whiteSpace:"nowrap",
},
"@media (min-width:720px)": {

},

});

openDropDownBox = styled(Typography)({
    position:"absolute",
    display: "flex",
    flexDirection: "column",
    width: "335px",
    height: "fit-content",
    opacity: "0px",
    backgroundColor: "#111111",
    border: "1px solid #595959",
    gap: "10px",
    cursor: "pointer",
    top:"3.5rem",
    borderRadius: "10px",
    zIndex:9,
"@media (max-width:960px)": {
  width: "fit-content",
},
"@media (min-width:720px)": {

},
});

DropDownBoxSecondTypography = styled(Typography)({
  color: "white",
  fontFamily: "Inter",
  fontSize: "20px",
  fontWeight: 600,
  lineHeight: "24px",
  letterSpacing: "0.3799999952316284px",
  textAlign: "left",
"@media (max-width:960px)": {
fontSize: "15px !important",
fontWeight: "300px !important",
lineHeight: "29.05px !important",
whiteSpace:"nowrap",

},
"@media (min-width:720px)": {

},

});

  SearchIconStyled = styled(SearchIcon)({
    color: '#66625C',
    fontSize: 18
  });

  CustomTabPanel = (props: TabPanelProps) => {
    const { children, value, index, ...other } = props;

    return (
      <Box
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
      </Box>
    );
  }


  a11yProps = (index: number) => {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
      label: index === 0 ? "Find Work" : "Saved Work",
      className: "tabs_css",
    };
  }

  StyledTabs = styled(Tabs)({
    '& .MuiTabs-wrapper': {
      justifyContent: 'flex-start',
      flexDirection: 'row'
    },
  });

  StyledButton = styled(Button)(({ theme }) => ({
    position: 'relative',
    borderRadius: '50px',
    background: '#313131',
    color: 'white',
    textTransform: 'none',
    fontWeight: 'bold',
    fontFamily: "Inter,sans-serif",
    overflow: 'hidden',
    fontSize: '20px', padding: '5% 10%',
    zIndex: 1,
    '&::before': {
      content: '""',
      position: 'absolute',
      inset: 0,
      borderRadius: '50px',
      padding: '2px',
      background: 'linear-gradient(224.84deg, #FFC29F 0%, #FFB9C7 16.15%, #CF8CCC 33.85%, #AB9EF5 50.52%, #6C98EE 67.71%, #1CBAE0 83.85%, #A8E5EC 100%)',
      WebkitMask: 'linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0)',
      WebkitMaskComposite: 'xor',
      maskComposite: 'exclude',
    },
    '&:hover': {
      background: '#313131',
    },
    [theme.breakpoints.down(1340)]: {
      fontSize: '17px',
      padding: '5% 10%',
    },

  }));
  renderOpportunitiesData = () => {
    const { loading, workOpportunityData } = this.state;

    if (loading) {
      return (
        <Box style={{ width: '100%', marginTop: '10%' }}>
          <Box style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <CircularProgress className="gray_color" size={25} />
          </Box>
          <Typography className="gray_color" style={{ marginTop: '20px', textAlign: 'center' }}>Searching...</Typography>
        </Box>
      );
    } else if (workOpportunityData.length > 0) {
      return (
        <Grid container spacing={2}>
          {workOpportunityData.map((element: workOpportunity, index: number) => (
            <Grid item xs={6} lg={4} key={index}>
              <OpportunityCard handlenavigate={this.handleNavigateFind} opportunity={element} handleOpenOpportunity={this.handleOpenOpportunity} />
            </Grid>
          ))}
        </Grid>
      );
    } else {
      return (
        <Box style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', width: "100%", marginTop: '15%', gap: 2 }}>
          <img src={searchImage} alt="search icon" height={84.39} width={171} />
          <Typography style={{ fontStyle: "Inter", fontSize: 20, fontWeight: 600, color: '#FFF2E3' }}>No work results found</Typography>
          <Typography style={{ fontStyle: "Inter", fontWeight: 400, color: '#FFF2E3', width: 550, textAlign: 'center', marginTop: "5%" }}>Sorry! We could not find any relevant job opportunities with your selected filters. Please try again by removing or applying different filters</Typography>
        </Box>
      );
    }
  };

  renderWorkDurationData = () => {
    return configJSON.workDurationData.data.map((element: SortingBySideBar) => {
      return <FormControlLabel key={element.id}
        control={<Checkbox onChange={() => this.handleWorkDurtationClick(element.value, element.id)} checked={this.state.workDurtationSelected.includes(element.value)} className="gray_color" id={`work_duration_${element.id}`}
          color="default" />}
        label={<Box className="gray_color">{element.name}</Box>}
      />
    })
  }

  renderExperienceLevelData = () => {
    return configJSON.experienceLevelData.data.map((element: SortingBySideBar) => {
      return <FormControlLabel key={element.id}
        control={<Checkbox checked={this.state.experienceSelected.includes(element.value)} onChange={() => this.handleExperienceClick(element.value)} className="gray_color" id={`experience_${element.id}`}
          color="default" />}
        label={<Box className="gray_color">{element.name}</Box>}
      />
    })
  }

  renderWorkTypeData = () => {
    return configJSON.workTypeData.data.map((element: SortingByWorkType) => {
      return <FormControlLabel key={element.id}
        control={<Checkbox checked={this.state.workTypeSelected.includes(element.value)} className="gray_color" id={`work_type_${element.id}`}
          onChange={() => this.handleWorkTypeClick(element.value)} color="default" />}
        label={<Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Box className="gray_color">{element.name}</Box>
          <Box>
            <Tooltip title={element.tooltip} placement="right-start">
              <IconButton className="gray_color" style={{ color: '#5C5C5C', border: '1px solid #5C5C5C', borderRadius: '30', fontSize: 10, padding: "2px 7px", marginLeft: 4 }}>
                i
              </IconButton>
            </Tooltip>
          </Box>
        </Box>
        }
      />
    })
  }

  renderHoursPerWeekData = () => {
    return configJSON.hoursPerWeekData.data.map((element: SortingBySideBar) => {
      return <FormControlLabel key={element.id}
        control={<Checkbox checked={this.state.hoursPerWeekSelected.includes(element.value)} className="gray_color" id={`hour_per_week_${element.id}`}
          onChange={() => this.handleHoursPerWeekClick(element.value)} color="default" />}
        label={<Box className="gray_color">{element.name}</Box>}
      />
    })
  }
  renderButtons = () => {
    const { currentNav } = this.state;
    return currentNav === 'find_work' ? (
      <Box className="find_work_and_praposals_button">
        <this.StyledButton variant="contained">
          Find Work
        </this.StyledButton>
        <Typography id="praposals_button" onClick={this.handleChangeCurrentNavData} className="praposal_text">
          Proposals
        </Typography>
      </Box>
    ) : (
      <Box className="find_work_and_praposals_button">
        <Typography id="find_work_button" onClick={this.handleChangeCurrentNavData} className="praposal_text">
          Find Work
        </Typography>
        <this.StyledButton variant="contained">
          Proposals
        </this.StyledButton>
      </Box>
    );
  };

  renderSection = () => {
    return <>
      <this.CustomTextField
        variant="outlined"
        size="small"
        id="find_work_search"
        data-test-id="find-text-elm"
        fullWidth
        onChange={this.handleFindWorkSearch}
        value={this.state.findWorkSearch}
        onKeyDown={(event) => this.handleKeyPress(event)}
        placeholder="Type here"
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <this.SearchIconStyled />
            </InputAdornment>
          ),
          endAdornment: (
            <InputAdornment position="end">
              {this.state.isSearched && <IconButton onClick={this.clearText} id="clear-text" style={{ color: "white", marginRight: 20, fontFamily: "Inter , sans-serif", }} size="small">
                x
              </IconButton>}
              <this.SearchButton onClick={this.handleApplyFilterButton} disabled={this.handleDisableSearch()}>
                Search
              </this.SearchButton>
            </InputAdornment>
          ),
        }}
      />

      <ThemeProvider theme={theme}>
        <Grid className="right_side_grid_box" container spacing={2}>
          {
            this.renderOpportunitiesData()
          }
        </Grid>
      </ThemeProvider>
    </>
  }

  mainContentManage = () => {
    let content;
    if (this.state.selectedOpportunity === "open_opportunity") {
      content = <Proposals cardId={this.state.activeId} navigation={this.props.navigation} />;
    } else if (this.state.selectedOpportunity === "draft") {
      content = <DraftOpportunity navigation={this.props.navigation} id={""} />
    }
    return content;
  }


  capitalizeFirstLetter(value:string) {
    if (value) {
    return value.replace(/\b[a-z]/g, (match) => match.toUpperCase());
    } else {
      return '';
    }
  }

  changeColor =(data:any)=>{
    this.setState({activeId: data.id })
    localStorage.setItem("CardId",data.id)
  }

  render() {
    return (
      <this.MainWrapper>
        {this.state.userType === "client" ?
          <>
            <NavigationMenu {...this.props} activeTab="my work" />
            <img src={Layer_1} width={"100%"} alt="My Work Poster" />
            <Box style={{ width: "100%", backgroundColor: "#111111", display: "flex", gap:"3rem",  boxSizing: 'border-box' }}>
              <Box style={{
                maxWidth: "400px",
                opacity: "0px",
                display: "flex",
                backgroundColor: "#111111",
                width: '30%',
                paddingTop:"40px"
              }}>
                <Box style={{
                  display: "flex",
                  flexDirection: "column",
                  width: "100%",
                  gap: "40px",
                  opacity: "0px",
                  marginLeft:"40px"

                }}>
                  <Box style={{
                    display: "flex",
                    justifyContent: "space-between",
                    opacity: "0px",

                  }}>
                    <Typography style={{
                      fontFamily: "Inter",
                      fontSize: "20px",
                      fontWeight: 600,
                      lineHeight: "24.2px",
                      textAlign: "left",
                      color: "#FFF2E3",
                    }}>My Work posts</Typography>

                    <Typography style={{
                      fontFamily: "Inter",
                      fontSize: "16px",
                      fontWeight: 600,
                      lineHeight: "19.36px",
                      textAlign: "left",
                      color: "#686868",
                      cursor:"pointer",
                      textDecoration: "underline",
                    }}>Explore more</Typography>

                  </Box>
                  <div style={{position:"relative"}}>

                  <Box
                  style={{
                    display: "flex",
                    gap:"32px",
                    flexDirection: "column",
                    opacity: "0px",
                  }} >

                    <this.DropDownBox
                    data-test-id="Typography-1"
                    onClick={() => { this.setState({ openDropDown: !this.state.openDropDown }) }}>
                      <this.DropDownBoxTypography >
                        <div style={{ height: "10px", width: "10px", borderRadius: "10px", backgroundColor: this.state.SelectedDropDownColor }}></div>
                        <Typography style={{
                          color: "white",
                          fontFamily: "Inter",
                          fontSize: "inherit",
                          fontWeight: 600,
                          lineHeight: "24px",
                          letterSpacing: "0.3799999952316284px",
                          textAlign: "left"
                        }}>{this.state.SelectedDropDownName}</Typography>
                      </this.DropDownBoxTypography>

                      <ArrowDropDownIcon style={{ width: "50px", height: "40px", color: "#D9D9D9" }} />
                    </this.DropDownBox>
                    <Box data-test-id="oportunityCard"  className="scrollOportunityCard" style={{width:"100%",display:"flex", flexDirection:"column",gap:"20px",
                     overflowY: "scroll", scrollbarWidth: 'none', maxHeight: '895px',cursor:"pointer"}} onScroll={(event)=> this.debounceHandle(event.target)}>
                      {
                        this.state.myWorkOpportinuity.map((data:any)=>{
                            return(
                              <Box className={this.state.activeId === data.id ? 'opportunity_card_main_containerChangeColor': "opportunity_card_main_container"} 
                              data-test-id="openOpportunity"  onClick={()=> this.changeColor(data)}>
                              <Box className='opportunity_card_title_box'>
                                <Typography className='opportunity_card_title only_2_line'>{data.attributes.title}</Typography>
                                <Box className='opportunity_card_price_duration_box'>
                                <Typography className='opportunity_card_price_duration opportunity_card_price_color'>Rs. {data.attributes.rate_amount} {data.attributes.rate_type !== "overall" && "pr/hr"}</Typography>
                                <Typography className='opportunity_card_price_duration opportunity_card_duration_color'>{data.attributes.project_timeline} {this.capitalizeFirstLetter(data.attributes.project_timeline_type)}</Typography>
                              </Box>
                                <Typography className='opportunity_card__description only_2_line'>{data.attributes.description}</Typography>
                              </Box>
                              <Box>
                                <Box className='opportunity_card_person_detain_box'>
                                  <Box >
                                    <Typography className='opportunity_card_hour'>{data.attributes.posted_time_ago}</Typography>
                                  </Box>
                                </Box>
                              </Box>
                            </Box>
                            )
                        })
                      }
                    </Box>
                    {
                      this.state.openDropDown && (
                        <this.openDropDownBox >
                          {configJSON.dropDownData.map((element: any, index: number) => {
                            return (
                              <Box data-test-id={`selectedDropDown-${index}`} onClick={() => this.dropDownViewData(element)}
                                style={{
                                  
                                  display: "flex",
                                  alignItems: "center",
                                  gap: "10px",
                                  padding: "1rem",
                                  cursor: "pointer"
                                }}>
                                <div style={{ height: "10px", width: "10px", borderRadius: "10px", backgroundColor: element.color }}></div>
                                <this.DropDownBoxSecondTypography data-test-id={`Typography-${index}`} onClick={() => { this.setState({ openDropDown: !this.state.openDropDown }) }} 
                                >{element.name}</this.DropDownBoxSecondTypography>
                              </Box>
                            )
                          })
                          }
                        </this.openDropDownBox>
                      )
                    }

                  </Box>
                  </div>
               
                </Box>
                
                <Box>
                </Box>
              </Box>
              <Box style={{height:"1000px", borderRight: "0.33px solid #6D6D6D"}}></Box>
              <Box style={{
                width:"70%",
                paddingTop:"40px"
              }}>
                {this.mainContentManage()}
              </Box>
          </Box>

          </>
          :
          <>
            <NavigationMenu {...this.props} activeTab="find work" />
            <img src={findWorkPoster} width={"100%"} alt="Find Work Poster" />
            <Box className="find_work_main_container">
              <Box className="find_work_side_bar_container">

                {this.renderButtons()}

                <Box className="sidebar_menu_box">
                  <Paper >
                    <MenuList className="sidebar_menu_list">
                      <MenuItem className="sidebar_menu_item">
                        <Accordion className="find_work_side_bar_container_menu">
                          <AccordionDetails>
                            <Box className="accordian_details" >
                              <Box>
                                <SortBy selectedSort={this.state.selectedSort} handleApply={this.handleSortSelect} />
                              </Box>
                              <Box >
                                <Button onClick={this.handleResetClick} disabled={this.handleDisableResetButton()} className={`apply_button ${this.handleDisableResetButton() ? "disabled-btn" : ""}`} size="small" style={{ textTransform: 'none', marginRight: 10, fontFamily: "Inter , sans-serif", }}>
                                  Reset
                                </Button>
                                <Button onClick={this.handleApplyFilterButton} disabled={this.handleDisableApplyFilterButton()} className={`apply_button ${this.handleDisableApplyFilterButton() ? "disabled-btn" : ""}`} size="small" style={{ textTransform: 'none', fontFamily: "Inter , sans-serif", }}>
                                  Apply
                                </Button>
                              </Box>
                            </Box>
                          </AccordionDetails>
                        </Accordion>
                        <Divider className="divider" />
                        <Accordion className="find_work_side_bar_container_menu">
                          <AccordionDetails>
                            <Box className="accordian_details">
                              <Box>
                                <Typography className="gray_color font_weight_600">Skill categories</Typography>
                              </Box>
                              <Box >
                                <CreativeFields fields={this.state.creativeFields}
                                  selectedFields={this.state.selectedCategories}
                                  onApply={this.handleCreativeFieldApply} />
                              </Box>
                            </Box>
                          </AccordionDetails>
                        </Accordion>
                        <Divider className="divider" />
                        <Accordion expanded={this.state.accordianToggle[0]} className="find_work_side_bar_container_menu">
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon className="gray_color" />}
                            aria-controls="panel1-content"
                            id="budget"
                            className="gray_color font_weight_600"
                            onClick={() => this.handleAccordianToggle(0)}
                          >
                            Budget
                          </AccordionSummary>
                          <AccordionDetails>
                            <Box className="find_work_sidebar_accordian">

                              <Box>
                                <img src={dancingBar} className="edit_profile_disco_bar" />
                                <Box className="slider_container">
                                  <Slider
                                    value={[this.state.rateMin, this.state.rateMax]}
                                    onChange={this.handleRangeSliderChange}
                                    min={100}
                                    max={5000}
                                    valueLabelDisplay="auto"
                                    aria-labelledby="range-slider"
                                  />
                                </Box>
                                <Box className="budget_count_box">
                                  <Box className="budget_min_max_box">
                                    <Typography className="budget_min_max_color">Min (per hour)</Typography>
                                    <Typography className="budget_count_color">{this.state.rateMin}</Typography>
                                  </Box>
                                  <Typography className="budget_min_max_color to_padding">to</Typography>
                                  <Box className="budget_min_max_box">
                                    <Typography className="budget_min_max_color">Max (per hour)</Typography>
                                    <Typography className="budget_count_color">{this.state.rateMax}</Typography>
                                  </Box>
                                </Box>
                              </Box>
                            </Box>
                          </AccordionDetails>
                        </Accordion>
                        <Divider className="divider" />
                        <Accordion expanded={this.state.accordianToggle[1]} className="find_work_side_bar_container_menu">
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon className="gray_color" />}
                            aria-controls="panel1-content"
                            id="location"
                            className="gray_color font_weight_600"
                            onClick={() => this.handleAccordianToggle(1)}
                          >
                            {configJSON.locationTitle}
                          </AccordionSummary>
                          <AccordionDetails>
                            <Box className="find_work_sidebar_accordian">

                              <FormControlLabel
                                data-test-id="btnToggle10"
                                id="remote_work"
                                value="Remote"
                                control={<Checkbox checked={this.state.isRemotLocationSelected} className="gray_color" />}
                                label={<Typography className="gray_color">Remote</Typography>}
                                onClick={this.handleRemoteWorkLocation}
                              />
                              <FormControlLabel
                                data-test-id="btnToggle11"
                                value={this.state.selectedLocation}
                                id="other_location"
                                control={<Checkbox onClick={this.handleWorkLocationSelected} className="gray_color" checked={this.state.isOtherLocationSelected} />}
                                label={<Box>
                                  <SelectLocation fields={this.state.locationListingData}
                                    selectedFields={this.state.selectedLocation}
                                    handleValue={this.handleLocationApplyFn}
                                    locationKey={"FindWorkKey"}
                                  />
                                </Box>
                                }
                              />

                            </Box>
                          </AccordionDetails>
                        </Accordion>
                        <Divider className="divider" />
                        <Accordion expanded={this.state.accordianToggle[2]} className="find_work_side_bar_container_menu">
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon className="gray_color" />}
                            aria-controls="panel1-content"
                            id="work_duration"
                            className="gray_color font_weight_600"
                            onClick={() => this.handleAccordianToggle(2)}
                          >
                            {configJSON.workDurationData.title}
                          </AccordionSummary>
                          <AccordionDetails>
                            <Box className="find_work_sidebar_accordian">
                              {this.renderWorkDurationData()}
                            </Box>
                          </AccordionDetails>
                        </Accordion>
                        <Divider className="divider" />
                        <Accordion expanded={this.state.accordianToggle[3]} className="find_work_side_bar_container_menu">
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon className="gray_color" />}
                            aria-controls="panel1-content"
                            id="work_type"
                            className="gray_color font_weight_600"
                            onClick={() => this.handleAccordianToggle(3)}
                          >
                            {configJSON.workTypeData.title}
                          </AccordionSummary>
                          <AccordionDetails>
                            <Box className="find_work_sidebar_accordian">
                              {this.renderWorkTypeData()}
                            </Box>
                          </AccordionDetails>
                        </Accordion>
                        <Divider className="divider" />
                        <Accordion expanded={this.state.accordianToggle[4]} className="find_work_side_bar_container_menu">
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon className="gray_color" />}
                            aria-controls="panel1-content"
                            id="experience_level"
                            className="gray_color font_weight_600"
                            onClick={() => this.handleAccordianToggle(4)}
                          >
                            {configJSON.experienceLevelData.title}
                          </AccordionSummary>
                          <AccordionDetails>
                            <Box className="find_work_sidebar_accordian">
                              {this.renderExperienceLevelData()}
                            </Box>
                          </AccordionDetails>
                        </Accordion>
                        <Divider className="divider" />
                        <Accordion expanded={this.state.accordianToggle[5]} className="find_work_side_bar_container_menu">
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon className="gray_color" />}
                            aria-controls="panel1-content"
                            id="hours_per_week" className="gray_color font_weight_600"
                            onClick={() => this.handleAccordianToggle(5)}
                          >
                            {configJSON.hoursPerWeekData.title}
                          </AccordionSummary>
                          <AccordionDetails>
                            <Box className="find_work_sidebar_accordian">
                              {this.renderHoursPerWeekData()}
                            </Box>
                          </AccordionDetails>
                        </Accordion>
                      </MenuItem>
                    </MenuList>
                  </Paper>
                </Box>

              </Box>
              <Box className="find_work_right_main_container">

                <Box>
                  <Box sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                  }}>
                    <this.StyledTabs id="tabs" onChange={this.handleTabValueChange} value={this.state.tabValues}
                      indicatorColor="primary"
                      className="Mui-indicator"
                    >
                      <Tab {...this.a11yProps(0)} />
                      <Tab {...this.a11yProps(1)} />
                    </this.StyledTabs>
                    <Box className="pagination">{
                      this.state.count > 1 && <Pagination onChange={this.handlePageChange} count={this.state.count} variant="text" defaultValue={this.state.page} shape="rounded" />
                    }
                    </Box>
                  </Box>
                  <Divider className="divider divider_for_tabs" />
                </Box>

                <this.CustomTabPanel value={this.state.tabValues} index={0}>
                  {this.renderSection()}
                </this.CustomTabPanel>
                <this.CustomTabPanel value={this.state.tabValues} index={1} data-test-id={'find-text'}>
                  {this.renderSection()}
                </this.CustomTabPanel>
              </Box>
            </Box>
            {this.state.openOppertunity && (
              <WorkOppertunityModal
                id="some-id"
                selectedOpportunityId={this.state.selectedOpportunityId}
                handleClose={this.handleClose}
                openOppertunity={this.state.openOppertunity}
                handleNavigate = {this.handleNavigation}
              />
            )}
          </>
        }
      </this.MainWrapper>
    );
  }
}

export default JobListing;
// Customizable Area End
